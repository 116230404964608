import React from "react";

function SectionHeading({ heading, mb = "mb-16p mb-7p md-fs", dailogId, ...rest }) {
    return (
        <div {...rest}>
            <h2 id={dailogId} className={"section-heading " + mb}>{heading}</h2>
        </div>
    );
}

export default SectionHeading;
