import React from "react";

const ReportButtons = ({
    label,
    iconClass,
    onClick,
    ariaLabel,
    isDisabled,
    tabIndex = 0,
    role
}) => {
    return (
        <div
            className={`fs text-semibold align-content-center ${isDisabled ? "disabled-text" : "hyper-links"
                }`}
            role={role ? "link" : "button"}
            aria-label={ariaLabel || label}
            aria-disabled={isDisabled}
            tabIndex={isDisabled ? -1 : tabIndex}
            onClick={() => {
                if (!isDisabled) onClick();
            }}
            onKeyDown={(e) => {
                if ((e.key === "Enter" || e.key === " ") && !isDisabled) {
                    onClick();
                }
            }}
            style={{
                cursor: isDisabled ? "not-allowed" : "pointer",
                outline: "none",
            }}
        >
            {label}
            {iconClass && <i className={`${iconClass} pl-8p`}></i>}
        </div>
    );
};

export default ReportButtons;
