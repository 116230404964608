import React from "react";

function SortIcon({ column }) {
    return (
        <div className="d-block text-center">
            {column.isSorted && (
                <i
                    style={{
                        color: "#025780",
                    }}
                    className={
                        "fa-solid " +
                        (column.isSortedDesc
                            ? "fa-solid fa-arrow-down-wide-short"
                            : "fa-solid fa-arrow-up-short-wide")
                    }
                ></i>
            )}
        </div>
    );
}
export default SortIcon;
