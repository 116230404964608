import React from "react";

function DropDownIcon(props) {
    return (
        <svg 
            width="24" 
            height="24" 
            fill="none" 
            stroke="currentColor" 
            stroke-width="2" 
            className={props?.className} 
            style={{ color: 'white' }}
        >
            <path d="M6 9L12 15 18 9">
            </path>
        </svg>
    );
}

export default DropDownIcon;
