import React from "react";

export default function ReportSaveNotification({
    onCloseNotification,
    msgIndicator,
    selectedView,
    titleInformation,
}) {
    const handleCloseNotification = () => {
        onCloseNotification();
    };

    const saveMessage = (msgIndicator) => {
        switch (msgIndicator) {
            case "NEW":
                return (
                    <div>
                        Your report has been saved and added to your My reports.
                    </div>
                );
            case "SAVE AS":
                return <div>Your report has been saved as new.</div>;
            case "UPDATE":
                return <div>Your report has been updated.</div>;
            case "DELETE":
                return <div>{selectedView.Name}</div>;
            case "SET":
                return <div>Your reminder has been set</div>;
            case "PUT":
                return <div>Your reminder has been updated</div>;
            case "Del":
                return <div>You will no longer receive this reminder</div>;
            default:
                return null;
        }
    };

    return (
        <div className="d-flex justify-content-end">
            <div
                className="position-absolute top-0 end-1 m-3"
                style={{ right: 45 }}
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
            >
                <div className="mt-20">
                    <div className="section-card Report-Notifications">
                        <div className="d-flex justify-content-between fw-bold">
                            <div
                                style={{
                                    color: "#5E9E42",
                                    fontSize: 20,
                                }}
                                aria-label={
                                    msgIndicator === "DELETE"
                                        ? "Report Deleted"
                                        : titleInformation === "reportShared"
                                            ? "Report Shared"
                                            : "Report Saved"
                                }
                            >
                                {msgIndicator === "DELETE"
                                    ? "Deleted"
                                    : titleInformation === "reportShared"
                                        ? "Shared"
                                        : "Saved"}
                            </div>
                            <button
                                style={{
                                    color: "#5E9E42",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                                onClick={handleCloseNotification}
                                aria-label="Close notification"
                            >
                                <i className="fa-solid fa-circle-xmark"></i>
                            </button>
                        </div>
                        <div className="fs text-semibold">
                            {saveMessage(msgIndicator)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
