import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "./ui/Button";
import { Modal } from "react-bootstrap";
import releaseImage from "../assets/images/Release_Message.jpeg";
import sparkleIcon from "../assets/icons/sparkleIcon.svg";
import {clearReleaseMessage} from "../slices/preferenceSlice";
import parse from 'html-react-parser';

const ReleaseMessage = ({ releaseHeader, releaseContent, releaseLink }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);

    const clearAndClose = () => {
        setShow(false);
        dispatch(clearReleaseMessage());
    };

    const handleClose = () => {
        clearAndClose();
    };

    const handleLearnMore = () => {
        if (releaseLink) {
            navigate(new URL(releaseLink).pathname);
        }
        clearAndClose();
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            clearAndClose();
        }, 1000000);

        return () => clearTimeout(timer);
    }, []);
 
    
    const removeBackticksAndPlaceholders = (input, sparkleIcon) => {
        return input
            .replace(/`/g, '')
            .replace(/\$\{sparkleIcon\}/g, sparkleIcon);
    };
    
    const cleanedContent = removeBackticksAndPlaceholders(releaseContent, sparkleIcon);

    return (
    <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        backdrop="static"
        className="Release-Message"
    >
        <div className="close-button" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
            Close
        </div>
        <Modal.Body className="p-0">
            <div className="modal-content-wrapper">
                <img 
                    src={releaseImage} 
                    alt="New Feature Logo" 
                    className="left-image"
                />
                <div className="text-content">
                    <label className="first-login-text">
                        {releaseHeader}
                        </label>
                       
                    <div className="release-content-container custom-scrollbar">
                        {parse(cleanedContent)}
                    </div>
                    <div className="first-login-buttons">
                        <Button onClick={handleLearnMore} text="Learn more" />
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
);

};

export default ReleaseMessage;
