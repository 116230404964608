import React from "react";
import mainLogo from "../assets/images/unum-logo.png";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import OrganizationLogo from "./OrganizationLogo";
import { displayLogo } from "../constants/index";

function Footer() {
    const { currentSelectedOrganization } = useSelector(
        (state) => state.organizations
    );
    const location = useLocation();
    let url = location.pathname;

    let urlDisplay = url.match(displayLogo);

    const currentYear = new Date().getFullYear();

    return (
        <footer className="py-20p footer-main" id="footer">
            <div className="container">
                <div className="row align-items-end px-20p">
                    <div className="col-md-5 copyright-container md-text-center">
                        <p className="copyright mb-10p">
                            &copy; {currentYear} Unum Group. All rights
                            reserved.
                        </p>

                        <p className="copyright mb-0">
                            Unum is a registered trademark and marketing brand
                            of Unum Group and its insuring subsidiaries.
                            Insurance products are underwritten by the
                            subsidiaries of Unum Group.
                        </p>
                    </div>

                    <div
                        className="col-md-7 text-right logo-container pe-0"
                        style={{
                            display: "flex",
                            justifyContent: "right",
                            alignItems: "end",
                        }}
                    >
                        <img
                            src={mainLogo}
                            className="logo mr-17p"
                            alt=""
                            style={{ marginRight: 17 }}
                            tabIndex={-1}
                        />
                        <div>
                            {urlDisplay !== null && (
                                <OrganizationLogo
                                    organizationName={
                                        currentSelectedOrganization?.ORGNIZATIONNAME
                                    }
                                    label={true}
                                    width={89.99}
                                    height={26.47}
                                    div={false}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
