import React, { useCallback } from "react";
import NoData from "../../components/alerts/NoData";
import SectionSubheading from "../../components/SectionSubheading";
import Table from "../../components/table/Table";
import GTM from "../../GTM";
import { separateByDelimiter } from "../../utils/helpers";
import LeaveProtections from "./LeaveProtections";

function LeavePeriods({ periodProtectionData, protections, print }) {
    const gtm = new GTM(null);

    const handleClick = (row) => {
        gtm.Event(
            "claim & leave",
            "accordion",
            `${row?.isExpanded === true ? "collapse" : "expand"}`,
            "received documentation",
            "leave summary",
            "leave record",
            "field_interaction",
            ""
        );
    };

    // Custom sorting logic for dates
    const dateSortType = useCallback((rowA, rowB) => {
        const parseDate = (dateString) => {
            const [month, day, year] = dateString.split("/");
            return new Date(year, month - 1, day);
        };

        const dateA = parseDate(rowA.original.Dates.split(" - ")[0]);
        const dateB = parseDate(rowB.original.Dates.split(" - ")[0]);

        return dateA - dateB;
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: "B",
                columns: [
                    {
                        Header: "Period",
                        accessor: (row) => row.LeavePeriod,
                        sortable: true,
                        tabIndex: 0,
                        width: 50,
                    },
                    {
                        Header: "Type",
                        accessor: (row) => row.Type,
                        sortable: true,
                        tabIndex: 0,
                        width: 70,
                    },
                    {
                        Header: "Hours Off",
                        accessor: (row) => row.HoursOff,
                        sortable: true,
                        tabIndex: 0,
                        width: 90,
                    },
                    {
                        Header: "Dates",
                        accessor: (row) => row.Dates,
                        sortable: true,
                        tabIndex: 0,
                        width: 140,
                        sortType: dateSortType,
                    },
                    {
                        Header: "Classification",
                        accessor: (row) =>
                            separateByDelimiter(row.Classification, "/"),
                        tabIndex: 0,
                        sortable: true,
                        width: 130,
                    },
                    {
                        Header: "Period Status",
                        accessor: (row) => row.Status,
                        sortable: true,
                        tabIndex: 0,
                        width: 120,
                    },
                    {
                        Header: "Period Status Reason",
                        accessor: (row) => row.StatusReason,
                        sortable: true,
                        tabIndex: 0,
                        width: 180,
                        Cell: ({ row }) => (
                            <div
                                className="d-flex justify-content-between align-items-center"
                                tabIndex={0}
                                onClick={() => handleClick(row)}
                            >
                                <span className="flex-1 mr-10p">
                                    {row.original.StatusReason}
                                </span>

                                <i
                                    className={
                                        "fa-solid accordion-icon " +
                                        (row?.isExpanded
                                            ? "fa-circle-minus"
                                            : "fa-circle-plus")
                                    }
                                ></i>
                            </div>
                        ),
                    },
                ],
            },
        ],
        [dateSortType]
    );

    const renderRowSubComponent = React.useCallback(
        ({ row }) => (
            <LeaveProtections
                row={row}
                print={print}
                protections={protections}
            />
        ),
        [print, protections]
    );

    return (
        <div style={{ paddingBottom: 10 }}>
            <SectionSubheading
                subheading="Leave Period/Protection"
                className="px-20p mb-20p"
            />

            {periodProtectionData.length === 0 ? (
                <div className="px-20p">
                    <NoData className="pb-0" />
                </div>
            ) : (
                <div
                    onClick={() => {
                        handleClick();
                    }}
                >
                    <Table
                        data={periodProtectionData}
                        columns={columns}
                        renderRowSubComponent={renderRowSubComponent}
                        pagination={!print && periodProtectionData.length > 10}
                        print={print}
                        className="print-data leave-period"
                        attribute1="period/protections"
                        pageName="claim & leave"
                        responsiveConfig={{
                            type: "ROW",
                            striped: false,
                            screen: "md",
                        }}
                        defaultPageSize={10}
                        height={
                            !print && periodProtectionData.length > 10
                                ? 329
                                : "auto"
                        }
                        style="d-flex"
                    />
                </div>
            )}
        </div>
    );
}

export default LeavePeriods;
