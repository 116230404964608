import React from "react";
import SortIcon from "./SortIcon";
import sortIcon from "../../assets/icons/sort-icon.svg";

function Header({ headerGroups, style, displayReportView }) {

    return (
        <div className="header">
            {headerGroups.map((headerGroup, index) => (
                <React.Fragment key={index}>
                    <div {...headerGroup.getHeaderGroupProps()} className="tr ">
                        {headerGroup.headers.map((column, idx) => (
                            <div
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                )}
                                className={"th " + style}
                                key={idx}
                            >
                                {column.render("Header")}

                                {column.canSort === true && (
                                    <div
                                        style={{
                                            paddingRight: 8,
                                            paddingLeft: 8,
                                        }}
                                    >
                                        {column.isSorted ? (
                                            <SortIcon column={column} />
                                        ) : (
                                            <img
                                                src={sortIcon}
                                                alt="Sort Icon"
                                                className="sort-icon"
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    {!displayReportView && (<div {...headerGroup.getHeaderGroupProps()} className="tr" >
                        {headerGroup.headers.map((column, idx) => (
                            <div
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                )}
                                className="th "
                                key={idx}
                            ></div>
                        ))}
                    </div>)}
                </React.Fragment>
            ))}
        </div>
    );
}

export default Header;
