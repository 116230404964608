import React from "react";
import leaveIcon from "../../assets/icons/leave-icon.svg";
import absenceIcon from "../../assets/icons/absence-icon.svg";
import lifeView from "../../assets/icons/report-life-icon.svg";
import changesIcon from "../../assets/icons/changes-icon.svg";
import paymentHistory from "../../assets/icons/payment-history-icon.svg";
import disabilityIcon from "../../assets/icons/disability-icon.svg";

const DataViewIcon = ({ dataViewType, onClick }) => {
    const getIconAndClassNameByType = (type) => {
        switch (type) {
            case "Disability":
                return {
                    icon: (
                        <img
                            src={disabilityIcon}
                            alt=""
                            width="16px"
                        />
                    ),
                    className: "disability icon",
                };
            case "Payments":
                return {
                    icon: (
                        <img
                            src={paymentHistory}
                            alt=""
                            width="16px"
                        />
                    ),
                    className: "payment icon",
                };
            case "Life":
                return {
                    icon: <img src={lifeView} alt="" width="16px" />,
                    className: "life icon",
                };
            case "Absence":
                return {
                    icon: <img src={absenceIcon} alt="" width="16px" height="16px"/>,
                    className: "absence icon",
                };
            case "Leave":
                return {
                    icon: <img src={leaveIcon} alt="" width="16px" />,
                    className: "leave icon",
                };
            case "Changes":
                return {
                    icon: <img src={changesIcon} alt="" width="29px" />,
                    className: "changes icon",
                };

            default:
                return { icon: null, className: "" };
        }
    };

    const { icon, className } = getIconAndClassNameByType(
        dataViewType.DataViewType
    );
    const handleOnClick = () => {
        onClick(dataViewType.Name, dataViewType);
    };

    return (
        <div
            className="d-flex align-items-center gap-2"
            onClick={handleOnClick}
        >
            <div className={`${className} frequently-used-icons`}>{icon}</div>
            <div style={{ color: "#025780" }} className="hyper-links"> {dataViewType.Name}</div>
        </div>
    );
};

export default DataViewIcon;
