import React, { useState } from "react";
import SectionHeading from "../../components/SectionHeading";
import Button from "../../components/ui/Button";
import Modal from "react-bootstrap/Modal";
import GTM from "../../GTM";
import { getButtonDisabledStatus } from "../../utils/helpers";

export default function ReportSavingPage({
    onShow,
    onSave,
    onDontSave,
    notification,
    reportViewType,
}) {
    const [viewNameChange, setViewNameChange] = useState(false);
    const [descriptionChange, setDescriptionChange] = useState(false);
    const [viewNameValue, setViewNameValue] = useState();
    const [descriptionValue, setDescriptionValue] = useState();

    const gtm = new GTM(null);

    const handleGtmTags = (label, attribute3) => {
        gtm.Event(
            "manage my reports",
            "button",
            "click",
            label,
            "report detail",
            reportViewType.toLowerCase(),
            attribute3,
            ""
        );
    };
    const sendSaveEvent = () => {
        onSave(viewNameValue, descriptionValue);
        notification("NEW");
        handleGtmTags("save", "save_report");
    };
    const sendDontSaveEvent = () => {
        setViewNameChange(false);
        setDescriptionChange(false);
        onDontSave();
        handleGtmTags("dont save", "field_interaction");
    };
    const handleViewNameChange = ({ target: input }) => {
        if (input.value.length && input.value.trim()) {
            setViewNameChange(true);
        } else {
            setViewNameChange(false);
        }
        setViewNameValue(input.value);
    };

    const handleDescriptionValueChange = ({ target: input }) => {
        if (input.value.length && input.value.trim()) {
            setDescriptionChange(true);
        } else {
            setDescriptionChange(false);
        }
        setDescriptionValue(input.value);
    };

    return (
        <>
            <Modal
                size="sm"
                show={onShow}
                backdrop="static"
                keyboard={false}
                centered
                className="preference"
                dialogClassName="custom-modal-size"
                role="dialog"
                id="myDialog"
                aria-labelledBy="dialogTitle"
            >
                <Modal.Header>
                    <SectionHeading heading="Save report" dailogId="dialogTitle"/>
                    <i
                        className="fa-solid fa-circle-xmark cursor-pointer"
                        onClick={sendDontSaveEvent}
                    ></i>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label>Report name</label>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Add a name"
                            onChange={handleViewNameChange}
                        />
                    </div>
                    <div>
                        <div>
                            <label
                                style={{
                                    paddingTop: 12,
                                }}
                            >
                                Description
                            </label>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Add a description"
                            onChange={handleDescriptionValueChange}
                        />
                    </div>
                    <div className="preference-button pt-16p">
                        <div className="d-contents">
                            <Button
                                data-testid="Don't save"
                                text="Don't save"
                                outline={true}
                                onClick={sendDontSaveEvent}
                            />
                            <Button
                                text="Save"
                                onClick={sendSaveEvent}
                                disabled={getButtonDisabledStatus(viewNameChange,viewNameValue,descriptionChange,descriptionValue)}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
