import React, { useEffect, useState } from "react";
import { getOrganizationLogo } from "../utils/helpers";
import { useSelector } from "react-redux";

function OrganizationLogo({
    organizationName,
    label = true,
    width = "auto",
    height = "auto",
    className = "",
    div = true,
    style = {},
}) {
    const [show, setShow] = useState(false);
    const [logo, setLogo] = useState(null);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!organizationName) return;

        const { show, logo } = getOrganizationLogo(organizationName);
        setShow(show);
        setLogo(logo);
    }, [organizationName]);

    return (
        <>
            {" "}
            {!user?.isUserInternal && show && logo && (
                <div
                    className={div ? "section-card col-md-3 logoCard" : ""}
                    tabIndex={-1}
                >
                    {label && (
                        <label
                            className={label ? "d-block fs-20" : ""}
                            style={
                                !div
                                    ? {
                                          fontSize: "xx-small",
                                          paddingBottom: 0,
                                          textAlign: "center",
                                      }
                                    : { color: "#015294", paddingBottom: 0 }
                            }
                        >
                            In partnership with
                        </label>
                    )}
                    <img
                        src={logo}
                        alt="logo"
                        height={height}
                        width={width}
                        style={style}
                    />
                </div>
            )}
        </>
    );
}

export default OrganizationLogo;
