import React from "react";

function Loader({ text = "Searching" }) {
    return (
        <div
            className="loader"
            role="alert"
            aria-busy="true"
            aria-live="polite"
        >
            <span>{text}</span>
            <div
                className="spinner-border"
                role="status"
                aria-hidden="true"
            ></div>
        </div>
    );
}

export default Loader;
